import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const lang = computed(() => {
      return store.state.lang;
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['app-' + _unref(lang)])
      }, [_createVNode(_component_router_view)], 2);
    };
  }
};